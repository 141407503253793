import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import intersectionBy from 'lodash/intersectionBy';
import extend from 'lodash/extend';
import sortBy from 'lodash/sortBy';
import orderBy from 'lodash/orderBy';
import { sortGridData, sortReportGridData } from '../utils/sorting';
import {
  COLUMN_COLID,
  SORT,
  CELL_RENDERER,
  oldKeyMapping,
  GROUP_COLUMN_CONFIG,
  TRADE_COLUMN_CONFIG,
  LOCKED_COLUMN_CONFIG
} from '../constants/pageConstants';

export const getNewGroupByViewData = (currentView, value) => {
  const activeView = cloneDeep(currentView);
  if (value === 'none') {
    activeView.columns = activeView.columns.filter(column => column.colId !== COLUMN_COLID.GROUP);
  } else {
    const index = activeView.columns.findIndex(column => column.colId === COLUMN_COLID.GROUP);
    if (index === -1) {
      const tradeColumn = activeView.columns.findIndex(column => column.colId === COLUMN_COLID.TRADE);
      activeView.columns.splice(tradeColumn + 1, 0, cloneDeep(GROUP_COLUMN_CONFIG));
    }
  }
  activeView.groupBy = value;
  activeView && activeView.columns.forEach((column, i) => {
    if (column.sort !== SORT.ASC && column.sort !== SORT.DESC) {
      column.sort = 'none';
    }
    column.columnIndex = i;
  });
  return activeView;
};

export const getDraftTradeData = (data) => {
  return {
    firm: {
      firmId: data.firmId,
      branchId: data.branchId
    },
    shareClass: {
      id: data.shareClassId
    },
    account: {
      id: data.accountId,
      taAccount: data.taAccountNumber,
      name: data.accountName
    },
    hasOpenAccounts: data.hasOpenAccounts
  };
};

export const getNewOrderedColumns = (columns, currentView, fromReporting) => {
  const currentVisibleColumn = columns.map(({colId}) => (colId.split("_")[0]));
  const activeView = cloneDeep(currentView);
  const {groupBy} = activeView;
  activeView.columns.forEach(column => delete column.columnIndex);
  let i = 0;
  currentVisibleColumn.forEach(colId => {
    const index = activeView.columns.findIndex(column => column.colId === colId);
    if (index !== -1) {
      activeView.columns[index].columnIndex = i;
      i++;
    }
  });
  activeView.columns.sort((a, b) => (a.columnIndex - b.columnIndex));

  /* If the request is from Reporting, and if the group by is 'none',
     then keep the group by column always on top, to avoid the grouped column from hidden */
  let sortedColumns;
  if (fromReporting && groupBy === "none") {
    const groupCol = activeView.columns.find(({colId}) => (colId === COLUMN_COLID.GROUP));
    const otherCol = activeView.columns.filter(({colId}) => (colId !== COLUMN_COLID.GROUP));
    sortedColumns = [groupCol, ...otherCol];
    sortedColumns.forEach((column, index) => {
      column.columnIndex = index;
    });
  } else {
    sortedColumns = orderBy(activeView.columns, ['columnIndex'], ['asc']);
  }
  return {...activeView, columns: sortedColumns};
};

export const getNewVisibleColumns = (colId, currentView) => {
  const activeView = cloneDeep(currentView);
  activeView.columns = activeView.columns.filter(column => column.colId !== colId);
  return activeView;
};

export const getNewWidthedColumns = (columns, currentView) => {
  const activeView = cloneDeep(currentView);
  activeView.columns.forEach(column => {
    const updateCol = columns.find(col => col.colId === column.colId);
    if (updateCol) {
      column.width = updateCol.width;
      column.suppressSizeToFit = true;
    }
  });
  return activeView;
};

export const getSortParams = (colId, order, props) => {
  if (!colId) return;
  const {currentView, columnsMetadata, preDefinedSortingState, selectedRows, groupByField} = props;
  const activeView = cloneDeep(currentView);
  activeView && activeView.columns.forEach(column => {
    if (column.colId !== COLUMN_COLID.GROUP) {
      column.sort = 'none';
    }
  });
  const colIdIndex = activeView.columns.findIndex(column => column.colId === colId);
  activeView.columns[colIdIndex].sort = order;
  const sortedColMetaData = columnsMetadata.find(column => column.colId === colId);
  const sortedMeta = [{...sortedColMetaData, sort: order, field: activeView.columns[colIdIndex].field}];
  if (colId !== COLUMN_COLID.GROUP && groupByField !== '') {
    const data = preDefinedSortingState && preDefinedSortingState.find(sortedMetaData => sortedMetaData.colId === COLUMN_COLID.GROUP);
    if (data) {
      sortedMeta.push(data);
    }
  }
  const params = {sortedMeta, selectedRows, isGroupColum: !(colId === COLUMN_COLID.GROUP)};
  return {activeView, params};
};

export const getSortedData = (gridData = [], params = {}) => {
  if (!gridData.length) return gridData;
  const {sortedMeta, selectedRows, isGroupColum} = params;
  return sortGridData(gridData, sortedMeta, selectedRows, isGroupColum);
};

export const getSortedReportData = (gridData = [], params = {}) => {
  if (!gridData.length) return gridData;
  const {sortedMeta, selectedRows, skipGroupSorting} = params;
  return sortReportGridData(gridData, sortedMeta, selectedRows, skipGroupSorting);
};

export const selectedRowsComparator = (selectedRows = [], rowData) => {
  if (!selectedRows.length) return;
  return !!selectedRows.find(data => data.id === rowData.accountId);
};

export const selectedRowsComparatorFund = (selectedRows = [], rowData) => {
  if (!selectedRows.length) return;
  return !!selectedRows.find(data => data.id === rowData.shareClassId);
};

export const updateActiveView = (allViews, activeViewValue) => {
  const views = allViews.map(view => ({...view, isActive: view.id === activeViewValue}));
  const {groupBy} = views.find(view => view.id === activeViewValue);
  return {groupBy, views};
};

export const replaceMatchedView = (allViews, activeView) => {
  const index = allViews.findIndex(view => (view.id === activeView.id));
  if (index > -1) {
    allViews[index] = {...activeView, isCustom: true};
  }
  return allViews.slice();
};

export const replaceActiveView = (allViews, activeView) => {
  const index = allViews.findIndex(view => view.isActive);
  if (index > -1) {
    allViews[index] = {...activeView, isCustom: true};
  }
  return allViews.slice();
};

export const getCurrentViewSelectorData = ({currentView, canTrade, isTradingDisabled}) => {
  Object.keys(oldKeyMapping).forEach(newKey => {
    const oldKey = oldKeyMapping[newKey];
    currentView[oldKey] = currentView[newKey];
  });
  if (currentView && currentView.columns) {
    if ((!canTrade || isTradingDisabled)) {
      return {...currentView, columns: currentView.columns.filter(column => column.colId !== COLUMN_COLID.TRADE)};
    } else {
      const index = currentView.columns.findIndex(column => column.colId === COLUMN_COLID.TRADE);
      if (index === -1) {
        let colIndex = 0;
        const columns = [{...TRADE_COLUMN_CONFIG, columnIndex: colIndex}];
        currentView.columns.forEach(column => {
          if (column.hasOwnProperty('columnIndex')) {
            columns.push({...column, columnIndex: ++colIndex});
          }
        });
        return {...currentView, columns};
      }
    }
  }
  return currentView;
};

export const getCurrentGridViewData = ({viewColumns, metadata, groupByColumns, labels}) => {
  const {columns = []} = viewColumns;
  if (!columns.length) return [];
  const intersectionColumns = intersectionBy(columns, metadata, 'field');
  const data = intersectionColumns.map((column) => {
    let metadataObject = metadata.find(data => data.colId === column.colId);
    if (metadataObject && metadataObject.proxyColumn) {
      const tempMetadataObject = {...metadataObject};
      metadataObject = metadata.find(data => data.field === column.field);
      metadataObject.headerName = tempMetadataObject.headerName;
      metadataObject.minWidth = tempMetadataObject.minWidth;
    }
    const category = metadataObject.category ? {category: labels[metadataObject.category]} : {};
    const transformedName = {
      headerTooltip: labels[metadataObject.headerName],
      headerName: labels[metadataObject.headerName]
    };
    return extend({}, metadataObject, column, transformedName, category);
  });

  const intersectionGroupColumns = [];
  groupByColumns.forEach(groupColumn => {
    const object = metadata.find((metaColumn) => {
      return groupColumn.value === metaColumn.field;
    });
    if (object) {
      const {headerName = ''} = object;
      intersectionGroupColumns.push({
        ...object,
        headerTooltip: labels[headerName] || headerName,
        headerName: labels[headerName] || headerName
      });
    }
  });
  intersectionGroupColumns.forEach(groupColumn => {
    const index = findIndex(data, viewsColumn => {
      return groupColumn.field === viewsColumn.field;
    });
    if (index >= 0) {
      // if hide is set on `groupColumn` set it on `data` as well; otherwise, default to false
      data[index].hide = !!groupColumn.hide;
    } else if (index === -1) {
      groupColumn.hide = true;
      data.push(groupColumn);
    }
  });

  data.forEach(header => {
    const {dependentColumns = []} = header;
    if (dependentColumns.length) {
      header.lockVisible = data.some(item => dependentColumns.includes(item.field));
    }
  });
  return sortBy(data, (header) => {
    return header.columnIndex;
  })
};

const getCellClass = params => {
  const value = `${params.value}`;
  if (value && params.colDef.searchTerm && value.toLowerCase().includes(params.colDef.searchTerm.toLowerCase())) {
    const result = ['yellow-background'];
    if (params.colDef.cellCustomClass) {
      result.push(...params.colDef.cellCustomClass);
    }
    return result;
  }
  return params.colDef.cellCustomClass || null;
};

export const gridHeaderData = ({headerData, groupByField, sortedColums, metadata, searchTerm = ''}) => {
  if(!headerData.length) return [];
  const groupFieldArr = [];
  if (groupByField && groupByField.includes('And')) {
    groupByField.split('And').forEach((token) => {
      groupFieldArr.push(token.trim());
    });
  } else {
    groupFieldArr.push(groupByField);
  }
  const groupColIndex = findIndex(headerData, (colDef) => colDef.colId === COLUMN_COLID.GROUP);
  if (groupColIndex !== -1 && groupByField !== 'none') {
    headerData[groupColIndex].groupFormatters = groupFieldArr.map(groupBy => {
      return metadata.find(data => data.field === groupBy).formatters || '';
    });
  }
  for (let i = groupColIndex + 1; i < headerData.length; i++) {
    if (!headerData[i].hide) {
      headerData[i] = (groupFieldArr[0] !== 'none') ? {...headerData[i], ...LOCKED_COLUMN_CONFIG} : headerData[i];
      break;
    }
  }

  let index = 1;
  const groupedHeaders = headerData.map(item => {
    delete item.rowGroupIndex;
    if (item.hasOwnProperty('width')) {
      item.suppressSizeToFit = true;
    }
    item.rowGroup = groupFieldArr.includes(item.field);
    if (item.rowGroup) {
      item.rowGroupIndex = groupFieldArr.indexOf(item.field);
    }
    if (item.hide) {
      item.columnIndex = '';
    } else {
      item.columnIndex = index;
      index++;
    }
    return item;
  }) || [];

  const headers = sortBy(groupedHeaders, (header) => header.columnIndex);
  const newHeaders = headers.map(a => ({...a}));
  if (sortedColums.length > 1) {
    const isGroupedColumn = newHeaders.findIndex(column => column.colId === COLUMN_COLID.GROUP);
    if (isGroupedColumn !== -1) {
      delete newHeaders[isGroupedColumn].sort;
    }
  }

  newHeaders.forEach(header => {
    if (header.sort === undefined || header.sort === 'none') {
      delete header.sort;
    }
    if (!searchTerm.length && header.cellRenderer === CELL_RENDERER.highlightCellRenderer) {
      delete header.cellRenderer;
    }
    if (header.cellCustomClass) {
      header.cellClass = header.cellCustomClass;
    }
  });
  if (searchTerm && searchTerm.length) {
     return newHeaders.map(data => {
      if (data.freeTextSearch) {
        return {
          ...data,
          searchTerm,
          cellRenderer: CELL_RENDERER.highlightCellRenderer,
          cellClass: getCellClass,
        };
      }
      return data;
    });
  }
  return newHeaders;
};

// replace lodash/noop
export const noop = () => {
  // returns undefined
};
