import React, { useState, useMemo, useEffect } from 'react';
import { Button } from '@gs-ux-uitoolkit-react/button';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import { Badge } from '@gs-ux-uitoolkit-react/badge';
import { isEqual } from 'lodash';
import useUserMaintenance from '../../../context';
import PageHeader from '../../../../../../components/app/PageHeader';
import { Conditional } from '../../../../../../components/core/Conditional';
import UserDetailsModal from '../Modal';
import MimicModal from '../../MimicModal';
import useSaveUserAccountStatus from '../../../hooks/useSaveUserAccountStatus';
import useSaveUserDetails from '../../../hooks/useSaveUserDetails';
import useSnackbar from '../../../hooks/useSnackbar';
import { DISCARD_CHANGES, EDIT_MODE_ACTIVE, OPEN_MODAL, SAVE_BTN_CLICKED, UPDATE_ERROR, TAB_SWITCHED, updateCurrentPage } from '../../../actions';
import { addPageContext } from '../../../../../../actions/page';
import { PAGES } from '../../../reducers';
import { MODAL, ACTIVE, INACTIVE } from '../../../constants';
import { TABS } from '../TabSwitchBar';
import translator from '../../../../../../services/translator';
import { dispatchAnalytics, userDetailsClose } from '../../../analytics';
import './index.scss';

const { translate: t } = translator;

const Header = () => {
  const {
    state: {
      isEditable,
      isPageEdited: isEdited,
      modal: [unsavedChangesModal, deactivationConfirmationModal],
      userDetails,
      isInputValid,
      accountEntitlements,
    },
    storeValues: { isInternalUser, isMosaicInternalUser, isMimicSessionActive },
    dispatch,
    reduxDispatch,
  } = useUserMaintenance();

  const isPageEdited = useMemo(() => {
    return isEdited || (accountEntitlements && !!accountEntitlements.length);
  }, [isEdited, accountEntitlements]);

  const { openSnackbar } = useSnackbar();
  const { id, firstName, lastName, login = '', userAccountStatus, isMigratedToSsa, organizationId } = userDetails;

  const handleClick = () => {
    dispatch({ type: TAB_SWITCHED, payload: TABS.PROFILE });
  };

  useEffect(() => {
    if (id && !organizationId) {
      openSnackbar({
        type: 'error',
        isHtmlMsg: true,
        autoHide: false,
        id: 'noOrgSnackbar',
        message: (
          <>
            <span>{t('tkNoOrgUserMsg')}</span>
            <span onClick={handleClick} className='snackbarHyperlink'>
              {t('tkOrganization')}
            </span>
          </>
        ),
      });
    }
  }, [id, organizationId]);

  const saveUserAccountStatus = useSaveUserAccountStatus();
  const { onSaveUserDetails } = useSaveUserDetails();

  const [isBackBtnClicked, setIsBackBtnClicked] = useState(false);

  const [showMimicModal, setShowMimicModal] = useState(false);

  const [statusClick, setStatusClick] = useState(false);

  const closeMimicModal = () => {
    setShowMimicModal(false);
    setStatusClick(false);
  };

  const onCancelClick = () => {
    if (isPageEdited) {
      dispatch({ type: OPEN_MODAL, payload: { modalName: MODAL.unsavedChanges } });
    } else {
      if (isBackBtnClicked) {
        setIsBackBtnClicked(false);
      }
      dispatch({ type: DISCARD_CHANGES });
    }
  };

  const onBackClickWithoutSave = () => {
    setIsBackBtnClicked(true);
    dispatch({ type: OPEN_MODAL, payload: { modalName: MODAL.unsavedChanges } });
  };

  const updateBackClickState = () => {
    setIsBackBtnClicked(false);
  };

  const activateUser = () => {
    if (isMimicSessionActive) {
      setStatusClick(true);
      setShowMimicModal(true);
      return;
    }
    dispatch({ type: OPEN_MODAL, payload: { modalName: MODAL.deactivationConfirmation } });
  };

  const onDeactivate = () => {
    if (isMimicSessionActive) {
      setStatusClick(true);
      setShowMimicModal(true);
      return;
    }
    dispatch({ type: OPEN_MODAL, payload: { modalName: MODAL.deactivationConfirmation } });
  };

  const onEdit = () => {
    if (isMimicSessionActive) {
      setShowMimicModal(true);
      return;
    }
    dispatch({ type: EDIT_MODE_ACTIVE, payload: { isEditable: true } });
  };

  const handleMimicOkBtnClick = () => {
    setShowMimicModal(false);
    dispatch({ type: EDIT_MODE_ACTIVE, payload: { isEditable: true } });
  };

  const onBack = () => {
    dispatchAnalytics(reduxDispatch, userDetailsClose());
    reduxDispatch(updateCurrentPage(PAGES.LANDING_PAGE));
    reduxDispatch(addPageContext({ pageKey: null }));
  };

  const isValid = () => {
    const inputValidated = Object.values(isInputValid).every(currentElement => currentElement.isValid === true);
    const validation = {};
    userDetails.ssoClient.forEach(data => {
      if (data.isEnabled) {
        validation[data.clientName] = { isValid: !!data.alias, errorMessage: !data.alias && t('tkIdentifierCannotBeEmpty') };
      }
    });
    dispatch({ type: UPDATE_ERROR, payload: { ...isInputValid, ...validation } });
    return inputValidated && Object.values(validation).every(currentElement => currentElement.isValid === true);
  };

  const saveChanges = () => {
    if (isMimicSessionActive) {
      setShowMimicModal(true);
      return;
    }
    dispatch({ type: SAVE_BTN_CLICKED, payload: { saveBtn: true } });
    if (isValid()) {
      const { includedList, excludedList } = userDetails.funds;
      const funds = {
        includedList: includedList.map(included => included.id),
        excludedList: excludedList.map(excluded => excluded.id),
      };
      // Accounts -> taking only latest change to firm/branch/fundAccount
      const accountEntitlementsCopy = JSON.parse(JSON.stringify(accountEntitlements));
      const indexesToBeDeleted = [];
      for (let i = 0; i < accountEntitlementsCopy.length - 1; i++) {
        const item1 = accountEntitlementsCopy[i];
        delete item1.capabilities;
        for (let j = i + 1; j < accountEntitlementsCopy.length; j++) {
          const item2 = accountEntitlementsCopy[j];
          delete item2.capabilities;
          if (isEqual(item1, item2)) {
            indexesToBeDeleted.push(i);
          }
        }
      }
      const finalAccountEntitlements = JSON.parse(JSON.stringify(accountEntitlements));
      if (indexesToBeDeleted.length) {
        let i = indexesToBeDeleted.length;
        while (i--) {
          finalAccountEntitlements.splice(indexesToBeDeleted[i], 1);
        }
      }

      onSaveUserDetails({
        userSaveDetails: [
          {
            ...userDetails,
            accountEntitlements: finalAccountEntitlements,
            funds: {
              ...userDetails.funds,
              ...funds,
            },
          },
        ],
      });
    } else {
      openSnackbar({ type: 'error', message: t('tkInvalidInputSnackbarMessage') });
    }
  };

  return (
    <div className='portfolio__header user-profile' data-testid='user-profile-header'>
      <Conditional condition={unsavedChangesModal.display}>
        <UserDetailsModal
          modalName='unsavedChanges'
          onBack={onBack}
          isBackBtnClicked={isBackBtnClicked}
          updateBackClickState={updateBackClickState}
        />
      </Conditional>
      <Conditional condition={deactivationConfirmationModal.display}>
        <UserDetailsModal modalName={MODAL.deactivationConfirmation} saveUserAccountStatus={saveUserAccountStatus} />
      </Conditional>
      {isMimicSessionActive && showMimicModal && (
        <MimicModal
          modalMsg={statusClick ? 'tkMimicActivateDeactivateUserMsg' : isEditable ? 'tkMimicSaveModalMsg' : 'tkMimicEditModalMsg'}
          closeModal={closeMimicModal}
          showCancelBtn={!isEditable && !statusClick}
          handleMimicOkBtnClick={handleMimicOkBtnClick}
        />
      )}

      <div className='control-panel'>
        <div className='control-panel__left'>
          <Button className='back-btn' actionType='primary' emphasis='minimal' onClick={isPageEdited ? onBackClickWithoutSave : onBack}>
            <Icon name='keyboard-arrow-left' type='filled' />
            {t('tkBacktoAdminDashboard')}
          </Button>
        </div>
        {isInternalUser && isMosaicInternalUser && isMigratedToSsa && (
          <div className='control-panel__right' data-testid='internal-edit-actions'>
            {isEditable ? (
              <>
                <Button actionType='primary' disabled={!isPageEdited} onClick={saveChanges} className='control-panel__right--Btns'>
                  {t('tkSave')}
                </Button>
                <Button actionType='primary' emphasis='subtle' onClick={onCancelClick} className='control-panel__right--Btns'>
                  {t('tkCancel')}
                </Button>
              </>
            ) : (
              userAccountStatus === ACTIVE && (
                <Button actionType='primary' onClick={onEdit} disabled={!organizationId} className='control-panel__right--Btns'>
                  <Icon name='edit' type='filled' size='sm' />
                  {t('tkEdit')}
                </Button>
              )
            )}

            {userAccountStatus === ACTIVE && (
              <Button actionType='destructive' emphasis='subtle' onClick={onDeactivate}>
                <Icon name='close' type='filled' />
                {t('tkDeactivate')}
              </Button>
            )}
            {userAccountStatus === INACTIVE && (
              <Button actionType='primary' onClick={activateUser} className='gs-uitk-button__button--primary-success'>
                <Icon name='check' type='filled' />
                {t('tkActivate')}
              </Button>
            )}
          </div>
        )}
      </div>
      <div className='user-profile-header'>
        {PageHeader({
          title: `${firstName} ${lastName}`,
          description: `${login || t('tkNullDataPlaceholder')}`,
        })}
        <div className='user-profile-header__status'>
          <Conditional condition={userAccountStatus === ACTIVE}>
            <Badge status='success'>{t('tkActive')}</Badge>
            <Badge>{t('tkInActive')}</Badge>
          </Conditional>
        </div>
      </div>
    </div>
  );
};

export default Header;
