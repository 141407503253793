import { TEXT, SWITCH, DROPDOWN, TEXT_WITH_SWITCH, sso, YES, NO, BOOLEAN, CREATE_USER, AUTOCOMPLETE_USER_DETAILS } from '../../../constants';
import { validateEmail, validateLength, validatePhone } from '../../../utils/edit/validation';

const arrayToStringFormatter = value => {
  if (value && value.length) {
    return Array.isArray(value) ? value.join(',') : value;
  }
  return null;
};

const yesAndNoFormatter = value => {
  return value ? YES : NO;
};

const ssoFormatter = data => data && data.isEnabled && data.alias;

const emailValidation = userInput => {
  if (!userInput) {
    return { error: false };
  }
  const { errorMessage: invalidLengthMessage } = validateLength(userInput, 0, 50);
  const { isValid: validEmail, errorMessage: invalidEmailMessage } = validateEmail(userInput);
  if (invalidLengthMessage || invalidEmailMessage) {
    return { error: true, errorMsg: !validEmail ? invalidEmailMessage : invalidLengthMessage };
  }
  return { error: false };
};

const phoneValidation = userInput => {
  if (!userInput) return { error: false };
  const { errorMessage: invalidLengthMessage } = validateLength(userInput, 0, 20);
  const { isValid: phoneNumberValid, errorMessage: invalidPhoneNumberMessage } = validatePhone(userInput);
  if (invalidLengthMessage || invalidPhoneNumberMessage) {
    return { error: true, errorMsg: !phoneNumberValid ? invalidPhoneNumberMessage : invalidLengthMessage };
  }
  return { error: false };
};

const ssoValidation = userInput => {
  const { isValid: validLength, errorMessage: invalidLengthMessage } = validateLength(userInput, 1, 50);
  if (invalidLengthMessage) {
    return { error: true, errorMsg: invalidLengthMessage };
  }
  return { error: validLength };
};

const organizationValueGetter = (userDetails = {}, pageKey) => {
  if (pageKey === CREATE_USER) {
    return {
      organizationId: userDetails.organizationId,
      organizationName: userDetails.organizationName || '',
    }
  } else {
    return userDetails.organizationName
  }
}

const readOnly = () => false;
const editableForCreate = (_, pageKey) => pageKey === CREATE_USER;

export const profileConfig = {
  identifyingInformationConfig: [
    {
      id: 1,
      label: 'tkFirstName',
      value: 'firstName',
      editableFieldType: TEXT,
      isProtectedForUser: false,
      editable: editableForCreate,
      required: true
    },
    {
      id: 2,
      label: 'tkLastName',
      value: 'lastName',
      editableFieldType: TEXT,
      isProtectedForUser: false,
      editable: editableForCreate,
      required: true
    },
    {
      id: 3,
      label: 'tkUserId',
      value: 'login',
      isProtectedForUser: false,
      editable: readOnly
    },
    {
      id: 4,
      label: 'tkOrganization',
      value: 'organizationId',
      valueGetter: organizationValueGetter,
      editableFieldType: AUTOCOMPLETE_USER_DETAILS,
      editable: editableForCreate,
      isProtectedForUser: false,
      required: true
    },
    {
      id: 5,
      label: 'tkGuid',
      value: 'userGuid',
      editable: readOnly,
      isProtectedForUser: false,
      required: false
    },
    {
      id: 6,
      label: 'tkiMoneyNetID',
      value: 'iMoneyNetId',
      editable: readOnly,
      isProtectedForUser: true,
      required: false
    },
    {
      id: 7,
      label: 'tkGSInternal',
      value: 'isInternal',
      editable: editableForCreate,
      editableFieldType: SWITCH,
      valueFormatter: yesAndNoFormatter,
      isProtectedForUser: true,
      dataType: BOOLEAN
    },
    {
      id: 8,
      label: 'tkMosaicInternal',
      value: 'isMosaicInternal',
      valueFormatter: yesAndNoFormatter,
      dataType: BOOLEAN,
      isProtectedForUser: true,
      editableFieldType: SWITCH,
      editable: (details, pageKey) => details.isInternal || pageKey === CREATE_USER
    },
  ],
  contactInformationConfig: [
    {
      id: 9,
      label: 'tkWorkEmailAddress',
      value: 'contactDetails.email',
      validation: emailValidation,
      isProtectedForUser: false,
      editableFieldType: TEXT,
      required: true
    },
    {
      id: 10,
      label: 'tkWorkPhone',
      value: 'contactDetails.phone',
      validation: phoneValidation,
      isProtectedForUser: false,
      editableFieldType: TEXT,
      required: false
    },
  ],
  thirdPartyPartnerSsoEntitlementsConfig: [
    {
      id: 11,
      label: 'tkClearwaterId',
      value: sso.Clearwater,
      isProtectedForUser: false,
      validation: ssoValidation,
      valueFormatter: ssoFormatter,
      editableFieldType: TEXT_WITH_SWITCH,
    },
    {
      id: 12,
      label: 'tkIonTreasuryId',
      value: sso.ION,
      isProtectedForUser: false,
      validation: ssoValidation,
      valueFormatter: ssoFormatter,
      editableFieldType: TEXT_WITH_SWITCH,
    },
    {
      id: 13,
      label: 'tkGTreasuryId',
      value: sso.GSTreasury,
      isProtectedForUser: false,
      validation: ssoValidation,
      valueFormatter: ssoFormatter,
      editableFieldType: TEXT_WITH_SWITCH,
    },
  ],
  setupConfig: [
    {
      id: 14,
      label: 'tkProfile',
      value: 'profile.name',
      isProtectedForUser: true,
      editableFieldType: DROPDOWN,
      dropdownKey: 'profile',
      required: true
    },
    {
      id: 15,
      label: 'tkAccessType',
      value: 'accessTypes',
      valueFormatter: arrayToStringFormatter,
      isProtectedForUser: true,
      editableFieldType: DROPDOWN,
      dropdownKey: 'accessTypes',
      required: true
    },
    {
      id: 16,
      label: 'tkAdminEntitlement',
      value: 'adminEntitlement',
      valueFormatter: arrayToStringFormatter,
      isProtectedForUser: true,
      hideOnEdit: true,
      editableFieldType: DROPDOWN,
      dropdownKey: 'adminEntitlement',
      required: true
    },
    {
      id: 17,
      label: 'tkTimeZone',
      value: 'timezone',
      isProtectedForUser: false,
      editableFieldType: DROPDOWN,
      dropdownKey: 'timezone',
      required: true
    },
    {
      id: 18,
      label: 'tkNSCCAccess',
      value: 'permissions.nscc',
      isProtectedForUser: true,
      editableFieldType: DROPDOWN,
      dropdownKey: 'nsccAccess',
      required: true
    },

    {
      id: 19,
      label: 'tkJurisdiction',
      value: 'jurisdiction',
      isProtectedForUser: false,
      editableFieldType: DROPDOWN,
      dropdownKey: 'jurisdiction',
      required: true
    },
  ],
  functionalityConfig: [
    /*
    {
      id: 19,
      label: 'tkShowClickThroughDisclaimer',
      value: 'showDisclaimerOnLogin',
      isProtectedForUser: true,
      useGetValue: true,
    },
    */
    {
      id: 20,
      label: 'tkEndClientBuffer',
      value: 'permissions.useCutoffClientBuffer',
      valueFormatter: yesAndNoFormatter,
      dataType: BOOLEAN,
      isProtectedForUser: true,
      editableFieldType: SWITCH
    },
    /*
    {
      id: 21,
      label: 'tkOverrideTradeImportTradeLimit',
      value: 'permissions.overrideTradeLimit',
      isProtectedForUser: true,
      useGetValue: true,
    },
    {
      id: 22,
      label: 'tkDenyBalanceTransferintoiMoneyNet',
      value: 'permissions.denyBalanceTransfer',
      isProtectedForUser: true,
      useGetValue: true,
    },
    */
    {
      id: 23,
      label: 'tkAllowShellAccounts',
      value: 'permissions.allowShellAccounts',
      valueFormatter: yesAndNoFormatter,
      dataType: BOOLEAN,
      isProtectedForUser: true,
      editableFieldType: SWITCH
    },
    {
      id: 24,
      label: 'tkAllowCloseouts',
      value: 'permissions.allowCloseouts',
      valueFormatter: yesAndNoFormatter,
      dataType: BOOLEAN,
      isProtectedForUser: true,
      editableFieldType: SWITCH
    },
  ],
  accessConfig: [
    {
      id: 25,
      label: 'tkMosaicLastLogin',
      value: 'userLoginInfo.currentLogin',
      isProtectedForUser: false,
    },
    {
      id: 26,
      label: 'tkSSOOnly',
      value: 'entitlements.featureEntitlements.disableLogin',
      isProtectedForUser: false,
      editableFieldType: SWITCH,
    },
    {
      id: 27,
      label: 'tkBlocked',
      value: 'isBlockedMfa',
      valueFormatter: yesAndNoFormatter,
      dataType: BOOLEAN,
      isProtectedForUser: false,
      editableFieldType: SWITCH
    },
  ],
  changeManagementDetails: [
    {
      id: 28,
      label: 'tkAccountCreationDate',
      value: 'createdAt',
      editable: readOnly,
      isProtectedForUser: false,
    },
    {
      id: 29,
      label: 'tkAccountProfileLastModifiedDate',
      value: 'lastUpdatedAt',
      editable: readOnly,
      isProtectedForUser: false,
      valueRenderer: "lastUpdatedAt"
    },
  ],
};
